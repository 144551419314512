
import { Options, Vue } from "vue-class-component";
import { text2EvaluateRowArray } from "./lib/EvaluateText";
import {
  averageArray,
  medianArray,
  roundDigit,
  totalArray,
} from "./lib/MathUtil";

import FormSelect from "@/components/Textarea.vue";
import EvaluateTr from "@/components/EvaluateTr.vue";

import { EvaluateRow } from "./interfaces/EvaluateRow";
import { Evaluates } from "./interfaces/Evaluates";

@Options({
  components: {
    FormSelect,
    EvaluateTr,
  },
})
export default class App extends Vue {
  public text = "";
  public evaluates: EvaluateRow[] = [];
  public evaluateKeys = Evaluates;
  public isExecuted = false;

  public exec(): void {
    this.evaluates = text2EvaluateRowArray(this.text);
    this.isExecuted = this.evaluates.length > 0;
  }

  public total(key: keyof Evaluates): number {
    const numbers = this.getEvaluateNumbers(this.evaluates, key);
    return totalArray(numbers);
  }

  public average(key: keyof Evaluates): number {
    const numbers = this.getEvaluateNumbers(this.evaluates, key);
    return roundDigit(averageArray(numbers), 2);
  }

  public median(key: keyof Evaluates): number {
    const numbers = this.getEvaluateNumbers(this.evaluates, key);
    return medianArray(numbers);
  }

  public shareOnX(): void {
    const labels = ["熱中", "斬新", "物語", "画像音声", "遊びやすさ", "その他"];

    const averages = Evaluates.map(this.average);
    const totals = Evaluates.map(this.total);
    const medians = Evaluates.map(this.median);
    const text =
      `投票数: ${this.evaluates.length}\n` +
      `項目: ${labels.join(" ")}\n` +
      `平均値: ${averages.join(" ")}\n` +
      `中央値: ${medians.join(" ")}\n` +
      `合計値: ${totals.join(" ")}\n` +
      "#ウディコン評価算出機\n" +
      "https://wodicon-evaluate.nononotyaya.net/";

    const url = `https://x.com/intent/post?text=${encodeURIComponent(text)}`;
    window.open(url, "_blank");
  }

  private getEvaluateNumbers(
    evaluates: EvaluateRow[],
    key: keyof Evaluates
  ): number[] {
    return evaluates.map((evaluate) => evaluate.score[key]);
  }
}
