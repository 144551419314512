import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-part" }
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = ["name", "rows", "cols", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          innerHTML: _ctx.labelText()
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: _normalizeClass(`resize-${_ctx.resize}`),
      name: _ctx.name,
      rows: _ctx.rows,
      cols: _ctx.cols,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args)))
    }, null, 42, _hoisted_3)
  ]))
}