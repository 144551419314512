import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString(_ctx.evaluate.name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.evaluate.score, (score) => {
      return (_openBlock(), _createElementBlock("td", {
        class: _normalizeClass(["number", `c${score}`]),
        key: score
      }, _toDisplayString(score), 3))
    }), 128))
  ]))
}